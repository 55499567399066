import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import Carousel from 'react-multi-carousel'

import useWindowSize from 'hooks/useWindowSize'

import Link from 'components/Link'
import CloudinarySolarnetImage from 'components/CloudinarySolarnetImage'
import Title from 'components/Title'

import s from './ModelsBlock.module.css'

function ModelsBlock({ womensTitle, women, mensTitle, men, className }) {
  const router = useRouter()

  const setWomensTitle = womensTitle ? womensTitle.split('\n') : null
  const setMensTitle = mensTitle ? mensTitle.split('\n') : null

  const windowSize = useWindowSize()
  const [gender, setGender] = useState('women')
  const [title, setTitle] = useState(setWomensTitle?.[0])
  const [subtitle, setSubtitle] = useState(setWomensTitle?.[1])
  const [ref, inView] = useInView({
    threshold: 0.9,
  })
  const [playSlider, setPlaySlider] = useState(false)

  useEffect(() => {
    if (inView) {
      setPlaySlider(true)
    } else {
      setPlaySlider(false)
    }
  }, [inView])

  if (!women && !men) return null

  const handleSlider = (pause) => {
    if (pause) {
      setPlaySlider(false)
    } else {
      setPlaySlider(true)
    }
  }

  const handleFemaleGender = () => {
    setGender('women')
    setTitle(setWomensTitle?.[0])
    setSubtitle(setWomensTitle?.[1])
    handleSlider('pause')
  }

  const handleMaleGender = () => {
    setGender('men')
    setTitle(setMensTitle?.[0])
    setSubtitle(setMensTitle?.[1])
    handleSlider('pause')
  }

  const handleFemaleBoard = () => {
    if (gender === 'women' || windowSize?.width >= 900)
      return router.push(`/${router.query?.site}/women`)
  }

  const handleMaleBoard = () => {
    if (gender === 'men' || windowSize?.width >= 900)
      return router.push(`/${router.query?.site}/men`)
  }

  const setImage = (url) => {
    return (
      <CloudinarySolarnetImage
        className={s.img}
        image={[
          {
            url: url?.replace('/web_start_picture', '/expanded'),
            params: 'w_286,h_387,ar_3:4,c_fit,g_north,f_auto',
            alt: '',
          },
        ]}
      />
    )
  }

  const setPosts = (posts) => {
    if (!posts) return null
    return (
      <div>
        <div className={s.posts}>
          {posts.slice(0, 6).map(({ image, link }, i) => {
            if (link && link.slug) {
              const slug = link.slug
              const title = link.title
              const imageUrl = image?.mediaItemUrl
              const coverUrl = link.acfModel?.coverImage
              return (
                <Link
                  type="Model"
                  key={`${slug}${i}`}
                  href={slug}
                  className={s.model}
                >
                  {(imageUrl || coverUrl) && (
                    <div className={s.imgWrap}>
                      {imageUrl
                        ? setImage(imageUrl)
                        : coverUrl && setImage(coverUrl)}
                    </div>
                  )}
                  {title && (
                    <h2
                      className={s.name}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  )}
                </Link>
              )
            }
          })}
        </div>
      </div>
    )
  }

  const sliderResponsive = {
    device: {
      breakpoint: { max: 10000, min: 0 },
      items: 1,
    },
  }

  const SliderButtons = ({ goToSlide }) => {
    return (
      <div className={s.links}>
        {women?.length > 0 && (
          <button
            type="button"
            className={classNames(s.link, s.linkWomens, {
              [s.linkActive]: gender === 'women',
            })}
            onClick={handleFemaleBoard}
            onMouseEnter={() => {
              handleFemaleGender(), goToSlide(2)
            }}
            onMouseLeave={() => handleSlider()}
          >
            Womens Board
          </button>
        )}
        {men?.length > 0 && (
          <button
            type="button"
            className={classNames(s.link, s.linkMens, {
              [s.linkActive]: gender === 'men',
            })}
            onClick={handleMaleBoard}
            onMouseEnter={() => {
              handleMaleGender(), goToSlide(3)
            }}
            onMouseLeave={() => handleSlider()}
          >
            Mens Board
          </button>
        )}
      </div>
    )
  }

  return (
    <div ref={ref} className={classNames(s.container, className)}>
      <div className={s.header}>
        <Title className={s.title} title={title} subtitle={subtitle} />
      </div>
      <Carousel
        containerClass={s.slider}
        responsive={sliderResponsive}
        arrows={false}
        infinite
        autoPlay={playSlider}
        autoPlaySpeed={4000}
        draggable={false}
        customTransition="none"
        ssr
        customButtonGroup={<SliderButtons />}
        renderButtonGroupOutside
        beforeChange={(nextSlide, { currentSlide }) => {
          setGender(currentSlide === 2 ? 'men' : 'women')
        }}
      >
        {setPosts(women)}
        {setPosts(men)}
      </Carousel>
    </div>
  )
}

ModelsBlock.propTypes = {
  womensTitle: PropTypes.string,
  women: PropTypes.array,
  mensTitle: PropTypes.string,
  men: PropTypes.array,
  className: PropTypes.string,
}
ModelsBlock.defaultProps = {
  womensTitle: '',
  women: [],
  mensTitle: '',
  men: [],
  className: '',
}

export default ModelsBlock
