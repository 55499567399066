import { useLazyQuery } from "@apollo/client";
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CATEGORY_MENU_QUERY from 'graphql/categoryMenu.query'

import Link from 'components/Link'
import CloudinaryMedia from 'components/CloudinaryMedia'
import Title from 'components/Title'
import Modal from 'components/Modal'
import ArticleTemplate from 'components/ArticleTemplate'

import s from './OurWorldBlock.module.css'

function OurWorldBlock({ posts, className }) {
  const router = useRouter()
  const [overlayPost, setOverlayPost] = useState(null)
  const [loadCategories, { data: catData, loading: catLoading }] = useLazyQuery(
    CATEGORY_MENU_QUERY,
    {
      context: { site: router.query.site },
    }
  )

  useEffect(() => {
    loadCategories()
  }, [])

  if (!posts) return null

  const hasCats = catData?.pageBy?.acfNews?.categoriesMenu
  const catLength = hasCats?.length + 1

  return (
    <>
      {hasCats && (
        <style
          dangerouslySetInnerHTML={{
            __html: `.${s.latestAnimation} {
            -webkit-animation: animation-latest${catLength} ${
              catLength * 2
            }s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
            animation: animation-latest${catLength} ${
              catLength * 2
            }s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
            }
            @-webkit-keyframes animation-latest2{0%{margin-top:-180px}5%{margin-top:-90px}50%{margin-top:-90px}55%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}@keyframes animation-latest2{0%{margin-top:-180px}5%{margin-top:-90px}50%{margin-top:-90px}55%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}
            @-webkit-keyframes animation-latest3{0%{margin-top:-270px}5%{margin-top:-180px}33%{margin-top:-180px}38%{margin-top:-90px}66%{margin-top:-90px}71%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}@keyframes animation-latest3{0%{margin-top:-270px}5%{margin-top:-180px}33%{margin-top:-180px}38%{margin-top:-90px}66%{margin-top:-90px}71%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}
            @-webkit-keyframes animation-latest4{0%{margin-top:-360px}5%{margin-top:-270px}25%{margin-top:-270px}30%{margin-top:-180px}50%{margin-top:-180px}55%{margin-top:-90px}75%{margin-top:-90px}80%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}@keyframes animation-latest4{0%{margin-top:-360px}5%{margin-top:-270px}25%{margin-top:-270px}30%{margin-top:-180px}50%{margin-top:-180px}55%{margin-top:-90px}75%{margin-top:-90px}80%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}
            @-webkit-keyframes animation-latest5{0%{margin-top:-450px}5%{margin-top:-360px}20%{margin-top:-360px}25%{margin-top:-270px}40%{margin-top:-270px}45%{margin-top:-180px}60%{margin-top:-180px}65%{margin-top:-90px}80%{margin-top:-90px}85%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}@keyframes animation-latest5{0%{margin-top:-450px}5%{margin-top:-360px}20%{margin-top:-360px}25%{margin-top:-270px}40%{margin-top:-270px}45%{margin-top:-180px}60%{margin-top:-180px}65%{margin-top:-90px}80%{margin-top:-90px}85%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}
            @-webkit-keyframes animation-latest6{0%{margin-top:-540px}13%{margin-top:-450px}20%{margin-top:-450px}27%{margin-top:-360px}34%{margin-top:-360px}41%{margin-top:-270px}48%{margin-top:-270px}65%{margin-top:-180px}72%{margin-top:-180px}79%{margin-top:-90px}86%{margin-top:-90px}93%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}@keyframes animation-latest6{0%{margin-top:-540px}13%{margin-top:-450px}20%{margin-top:-450px}27%{margin-top:-360px}34%{margin-top:-360px}41%{margin-top:-270px}48%{margin-top:-270px}65%{margin-top:-180px}72%{margin-top:-180px}79%{margin-top:-90px}86%{margin-top:-90px}93%{margin-top:0}99.99%{margin-top:0}100%{margin-top:-270px}}
          }`,
          }}
        />
      )}
      <div className={classNames(s.container, className)}>
        <div className={s.header}>
          <Title title="Explore" subtitle="Our World" />
          <Link type="Page" href="news" className={s.latest}>
            <div className={s.latestAnimation}>
              {catData?.pageBy?.acfNews?.categoriesMenu
                ?.slice(0, 5)
                .map(({ name }) => (
                  <span key={name} dangerouslySetInnerHTML={{ __html: name }} />
                ))}
              <span>Latest News</span>
            </div>
            Latest News
          </Link>
        </div>
        <Modal
          className={classNames(s.overlay, {
            [s.overlayVisible]: overlayPost,
          })}
          closeClassName={s.overlayCloseBtn}
          isShowing={overlayPost !== null}
          hide={() => setOverlayPost(null)}
          closeBtn
        >
          <ArticleTemplate
            className={s.overlayContent}
            classNameArticles={s.overlayArticles}
            data={{ postBy: overlayPost }}
          />
        </Modal>
        <div className={s.posts}>
          {posts.map((post) => {
            const { slug, title, acfPost, acfPostSettings } = post
            const url = acfPost?.featuredImage?.mediaItemUrl
            const modelTitle = acfPostSettings?.relatedModels?.[0]?.title
            return (
              <div key={slug} className={s.postWrap}>
                <button
                  type="button"
                  className={s.overlayBtn}
                  onClick={() => setOverlayPost(post)}
                />
                <Link type="Article" href={slug} className={s.post}>
                  {url && (
                    <div className={s.postImgWrap}>
                      <CloudinaryMedia
                        images={[{ url, params: 'w_684,ar_3:4,c_fill,f_auto' }]}
                        className={s.postImg}
                      />
                    </div>
                  )}
                  {modelTitle && (
                    <h2
                      className={s.postTitle}
                      dangerouslySetInnerHTML={{
                        __html: modelTitle,
                      }}
                    />
                  )}
                  {title && (
                    <h3
                      className={s.postSubtitle}
                      dangerouslySetInnerHTML={{
                        __html:
                          title.substr(0, title.lastIndexOf('|')) || title,
                      }}
                    />
                  )}
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

OurWorldBlock.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.string,
}
OurWorldBlock.defaultProps = {
  posts: [],
  className: '',
}

export default OurWorldBlock
