import { Fragment, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import CloudinaryMedia from 'components/CloudinaryMedia'
import Title from 'components/Title'
import SliderBlock from 'components/SliderBlock'

import s from './BecomeAModel.module.css'

function BecomeAModel({ promo, className }) {
  if (promo.images?.length < 1) return null

  const sliderRef = useRef(null)
  const [ref, inView] = useInView({
    threshold: 0.9,
  })

  useEffect(() => {
    const slider = sliderRef?.current
    if (slider) {
      if (inView) {
        slider.slickNext()
        slider.slickPlay()
      } else {
        slider.slickPause()
      }
    }
  })

  const { images, title, description, link } = promo

  const setTitle = title ? title.split('\n') : null

  const sliderSettings = {
    fade: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div ref={ref} className={classNames(s.container, className)}>
      <div className={s.wrapper}>
        {images && (
          <SliderBlock
            parentRef={sliderRef}
            className={s.slider}
            settings={sliderSettings}
          >
            {images.map(({ image }) => {
              const url = image?.mediaItemUrl
              if (url) {
                return (
                  <Fragment key={url}>
                    <CloudinaryMedia
                      className={s.img}
                      images={[{ url, params: 'f_auto' }]}
                    />
                  </Fragment>
                )
              }
            })}
          </SliderBlock>
        )}
        <div className={s.infoWrapper}>
          <div className={s.info}>
            {setTitle && (
              <Title
                className={s.title}
                title={setTitle[0]}
                subtitle={setTitle[1] || null}
                borderTitle
                lightSubtitle
              />
            )}
            {description && (
              <p
                className={s.description}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {link && (
              <a
                href={link.url}
                className={s.link}
                dangerouslySetInnerHTML={{
                  __html: link.title || 'Apply to become a model',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

BecomeAModel.propTypes = {
  promo: PropTypes.object,
  className: PropTypes.string,
}
BecomeAModel.defaultProps = {
  promo: {},
  className: '',
}

export default BecomeAModel
