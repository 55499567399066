import { gql } from "@apollo/client";

const CATEGORY_MENU_QUERY = gql`
  query CATEGORY_MENU_QUERY {
    pageBy(uri: "news") {
      acfNews {
        categoriesMenu {
          ... on Category {
            name
          }
        }
      }
    }
  }
`

export default CATEGORY_MENU_QUERY
