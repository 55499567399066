import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import Carousel from 'react-multi-carousel'

import useWindowSize from 'hooks/useWindowSize'

import Link from 'components/Link'
import CloudinarySolarnetImage from 'components/CloudinarySolarnetImage'
import Title from 'components/Title'

import s from './CombinedModelsBlock.module.css'

function CombinedModelsBlock({ title, models, className }) {
  const router = useRouter()

  const titleParts = title.split('\n').filter((part) => part !== '')

  const mainTitle = titleParts[0]
  const subTitle = titleParts[1]

  const windowSize = useWindowSize()
  const [gender, setGender] = useState('women')
  const [ref, inView] = useInView({
    threshold: 0.9,
  })
  const [playSlider, setPlaySlider] = useState(false)

  useEffect(() => {
    if (inView) {
      setPlaySlider(true)
    } else {
      setPlaySlider(false)
    }
  }, [inView])

  if (!models) return null

  const setImage = (url) => {
    return (
      <CloudinarySolarnetImage
        className={s.img}
        image={[
          {
            url: url?.replace('/web_start_picture', '/expanded'),
            params: 'w_286,h_387,ar_3:4,c_fit,g_north,f_auto',
            alt: '',
          },
        ]}
      />
    )
  }

  const setPosts = (posts) => {
    if (!posts) return null
    return (
      <div>
        <div className={s.posts}>
          {posts.slice(0, 6).map(({ image, link }, i) => {
            if (link && link.slug) {
              const slug = link.slug
              const title = link.title
              const imageUrl = image?.mediaItemUrl
              const coverUrl = link.acfModel?.coverImage
              return (
                <Link
                  type="Model"
                  key={`${slug}${i}`}
                  href={slug}
                  className={s.model}
                >
                  {(imageUrl || coverUrl) && (
                    <div className={s.imgWrap}>
                      {imageUrl
                        ? setImage(imageUrl)
                        : coverUrl && setImage(coverUrl)}
                    </div>
                  )}
                  {title && (
                    <h2
                      className={s.name}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  )}
                </Link>
              )
            }
          })}
        </div>
      </div>
    )
  }

  const sliderResponsive = {
    device: {
      breakpoint: { max: 10000, min: 0 },
      items: 1,
    },
  }

  return (
    <div ref={ref} className={classNames(s.container, className)}>
      <div className={s.header}>
        <Title className={s.title} title={mainTitle} subtitle={subTitle} />
      </div>
      <Carousel
        containerClass={s.slider}
        responsive={sliderResponsive}
        arrows={false}
        infinite
        autoPlay={playSlider}
        autoPlaySpeed={4000}
        draggable={false}
        customTransition="none"
        ssr
        renderButtonGroupOutside
        beforeChange={(nextSlide, { currentSlide }) => {
          setGender(currentSlide === 2 ? 'men' : 'women')
        }}
      >
        {setPosts(models)}
      </Carousel>
    </div>
  )
}

CombinedModelsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
}
CombinedModelsBlock.defaultProps = {
  className: '',
}

export default CombinedModelsBlock
