import PropTypes from 'prop-types'
import classNames from 'classnames'

import WordpressLink from 'components/WordpressLink'
import CloudinaryMedia from 'components/CloudinaryMedia'
import SliderBlock from 'components/SliderBlock'

import s from './OurWorldExtendedBlock.module.css'

function OurWorldExtendedBlock({ posts, className }) {
  if (posts?.length < 1) return null

  const sliderSettings = {
    fade: true,
    infinite: true,
    swipe: false,
    draggable: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className={classNames(s.container, className)}>
      <div className={s.posts}>
        {posts
          .filter((p) => p.link && p.link.slug)
          .map(
            (
              { id, type, link, video, videoWebm, images, title, subtitle },
              i
            ) => (
              <WordpressLink
                key={`${title}_${link.slug}`}
                className={s.post}
                link={link}
              >
                {type === 'video' ? (
                  <CloudinaryMedia
                    videos={[
                      {
                        url: videoWebm?.mediaItemUrl,
                        type: videoWebm?.mimeType,
                        // params: 'f_auto,vc_auto,ac_none',
                      },
                      {
                        url: video?.mediaItemUrl,
                        type: video?.mimeType,
                        // params: 'f_auto,vc_auto,ac_none',
                      },
                    ]}
                    videoLoop
                    className={s.media}
                  />
                ) : (
                  <SliderBlock
                    // parentRef={sliderRef[i]}
                    className={s.slider}
                    settings={sliderSettings}
                  >
                    {images
                      ?.filter((i) => i.image?.mediaItemUrl)
                      .map(({ image, title }) => {
                        const url = image?.mediaItemUrl
                        return (
                          <div key={url}>
                            <CloudinaryMedia
                              images={[
                                {
                                  url,
                                  params: 'w_1.0,ar_1:1,c_fill,g_north,f_auto',
                                },
                              ]}
                              className={s.media}
                            />
                            <h2
                              className={s.title}
                              dangerouslySetInnerHTML={{
                                __html: title,
                              }}
                            />
                          </div>
                        )
                      })}
                  </SliderBlock>
                )}
                {type === 'video' && (
                  <h2
                    className={s.title}
                    dangerouslySetInnerHTML={{
                      __html: title || '',
                    }}
                  />
                )}
                <h3
                  className={s.subtitle}
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              </WordpressLink>
            )
          )}
      </div>
    </div>
  )
}

OurWorldExtendedBlock.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.string,
}
OurWorldExtendedBlock.defaultProps = {
  posts: [],
  className: '',
}

export default OurWorldExtendedBlock
