/**
 * @param {Date} date
 * @param {string} locale
 * @param {} options
 *
 * @return {string}
 */
const formatDate = (date, locale = 'en-US', options = {dateStyle: 'medium'}) => {
  // Currently, we only use the GB format, but maybe we should use something better for America? ;-)
  return date.toLocaleDateString(locale, options)
}

export default formatDate;