import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Title from 'components/Title'
import Modal from 'components/Modal'

import s from './InstagramBlock.module.css'

function InstagramBlock({ handle, posts, className }) {
  if (posts === null || posts?.length < 1) return null
  const [overlayVisible, setOverlayVisible] = useState(false)

  const handleOpenOverlay = () => {
    setOverlayVisible(true)
    disableBodyScroll()
  }

  const handleCloseOverlay = () => {
    setOverlayVisible(false)
    clearAllBodyScrollLocks()
  }

  const setPosts = (array) =>
    array
      .filter((f) => f.mediaType !== 'VIDEO')
      .slice(0, 9)
      .map(({ url, id }) => {
        if (!url) return null
        return (
          <button
            key={`${id}_${url}`}
            type="button"
            className={s.btn}
            onClick={handleOpenOverlay}
          >
            {/* TODO: We could use Cloudinary to convert this. */}
            <div className={s.imgWrapper}>
              <img className={s.img} src={url} alt="" />
            </div>
          </button>
        )
      })

  return (
    <div className={classNames(s.container, className)}>
      <Title
        className={s.title}
        title="Instagram"
        subtitle="Follow us"
        swapColour
      />
      <div className={s.posts}>{setPosts(posts)}</div>
      <Modal
        isShowing={overlayVisible}
        hide={handleCloseOverlay}
        className={s.overlay}
        closeClassName={s.overlayCloseBtn}
        closeBtn
      >
        <div className={s.posts}>{setPosts(posts)}</div>
        {handle && (
          <a
            href={`https://www.instagram.com/${handle}`}
            className={s.overlayLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in instagram
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H9.5M9.5 1V10M9.5 1L1.5 9"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </a>
        )}
      </Modal>
      {handle && (
        <a
          href={`https://www.instagram.com/${handle}`}
          className={s.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {handle}
        </a>
      )}
    </div>
  )
}

InstagramBlock.propTypes = {
  handle: PropTypes.string.isRequired,
  posts: PropTypes.array,
  className: PropTypes.string,
}
InstagramBlock.defaultProps = {
  handle: '',
  posts: [],
  className: '',
}

export default InstagramBlock
