import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import { apolloClient } from 'lib/withApollo'
import getSitesForStaticPaths from 'helpers/getSitesForStaticPaths'
import toTitleCase from 'helpers/toTitleCase'
import INDEX_QUERY from 'graphql/index.query'

import Loading from 'components/Loading'
import SplashText from 'components/SplashText'
import WordpressSEO from 'components/WordpressSEO'
import Layout from 'components/Layout'
import SliderCover from 'components/SliderCover'
import OurWorldBlock from 'components/BlocksHomepage/OurWorldBlock'
import OurWorldExtendedBlock from 'components/BlocksHomepage/OurWorldExtendedBlock'
import InstagramBlock from 'components/BlocksHomepage/InstagramBlock'
import ModelsBlock from 'components/BlocksHomepage/ModelsBlock'
import CombinedModelsBlock from 'components/BlocksHomepage/CombinedModelsBlock'
import BecomeAModel from 'components/BecomeAModel'

import s from './index.module.css'
import { SitesContext } from '../../context/SitesContextProvider'

// TODO: setup proptypes
function Index({
  title,
  seo,
  site,
  acfHomepage,
  instagrams,
  ourWorldLatestPosts,
  globalSettings,
}) {
  const { sites } = useContext(SitesContext)
  const isSingleSite = typeof sites !== 'undefined' && sites.length === 1
  const [locationsSplash, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const loadingTimer = setTimeout(() => setLoading(false), 1000 * 1.7)
    return () => clearTimeout(loadingTimer)
  }, [site])

  if (locationsSplash) {
    return (
      <>
        <WordpressSEO url={`/${site}`} title={title} seo={seo} />
        <Loading>
          {!isSingleSite && locationsSplash && (
            <SplashText location={toTitleCase(site.replace('-', ' '))} />
          )}
        </Loading>
      </>
    )
  }

  const {
    highlightOrder,
    exploreOurWorld,
    exploreOurWorldPosts,
    exploreOurWorldExtended,
    modelsInFocusTitle,
    modelsInFocus,
    modelsInFocusWomensTitle,
    modelsInFocusWomens,
    modelsInFocusMensTitle,
    modelsInFocusMens,
    promo,
  } = acfHomepage

  const highlightCovers = highlightOrder ? highlightOrder.map((c) => c) : []
  const igHandle =
    globalSettings?.offices?.nodes?.[0]?.acfOffices?.instagramHandle || ''

  const lazyLoad = (children) => {
    if (!children) return null
    return (
      <LazyLoad height={350} once>
        {children}
      </LazyLoad>
    )
  }

  return (
    <Layout globalSettings={globalSettings}>
      <WordpressSEO url={`/${site}`} title={title} seo={seo} />
      <SliderCover covers={highlightCovers} />
      <div className={s.container}>
        {lazyLoad(
          <OurWorldBlock
            posts={
              exploreOurWorld === 'latest-posts' && ourWorldLatestPosts?.nodes
                ? ourWorldLatestPosts.nodes
                : exploreOurWorldPosts
            }
          />
        )}
        {lazyLoad(<OurWorldExtendedBlock posts={exploreOurWorldExtended} />)}
        {lazyLoad(<InstagramBlock handle={igHandle} posts={instagrams} />)}
        {modelsInFocus && modelsInFocus.length > 0
          ? lazyLoad(
              <CombinedModelsBlock
                title={modelsInFocusTitle}
                models={modelsInFocus}
              />
            )
          : lazyLoad(
              <ModelsBlock
                womensTitle={modelsInFocusWomensTitle}
                women={modelsInFocusWomens}
                mensTitle={modelsInFocusMensTitle}
                men={modelsInFocusMens}
              />
            )}
      </div>
      {site !== 'modellink' && lazyLoad(<BecomeAModel promo={promo} />)}
    </Layout>
  )
}

Index.propTypes = {
  pageUrl: PropTypes.string,
  pageTitle: PropTypes.string,
  pageDesc: PropTypes.string,
  globalSettings: PropTypes.object,
}
Index.defaultProps = {
  pageUrl: '',
  pageTitle: '',
  pageDesc: '',
  globalSettings: {},
}

export default Index

export async function getStaticProps({ params }) {
  const { site } = params

  const { data, error } = await apolloClient().query({
    query: INDEX_QUERY,
    context: { site },
    fetchPolicy: 'no-cache', // added because we were getting a stale response for '/chicago' route
  })

  if (error) console.log(`Error loading Index props - ${error}`)

  if (!data) return { props: {} }

  return {
    props: {
      ...params,
      ...data.pageBy,
      instagrams: data.instagrams,
      ourWorldLatestPosts: data.ourWorldLatestPosts,
    },
    revalidate: 60 * 2,
  }
}

export async function getStaticPaths() {
  const sites = await getSitesForStaticPaths()

  const paths = sites.map((site) => ({
    params: {
      site: site,
    },
  }))

  return {
    paths,
    fallback: false,
  }
}
