import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/Link'
import CloudinaryMedia from 'components/CloudinaryMedia'

import s from './ArticlesRelated.module.css'

function ArticlesRelated({ articles, className, wrapperClassName }) {
  if (articles === null || articles.length < 1) return null

  return (
    <div className={classNames(s.container, className)}>
      <div className={classNames(s.wrapper, wrapperClassName)}>
        {articles
          .filter((f) => f.acfPost.featuredImage !== null)
          .map(({ slug, title, acfPost, acfPostSettings }) => {
            const setRelatedModels = acfPostSettings?.relatedModels
              ?.map((rm) => rm.title)
              .join(', ')
              .replace(/,(?!.*,)/gim, ' &')
            return (
              <Link key={slug} href={slug} type="Article" className={s.project}>
                <div className={s.imgWrap}>
                  <CloudinaryMedia
                    images={[
                      {
                        url: acfPost?.featuredImage?.mediaItemUrl,
                        params: 'w_510,f_auto',
                      },
                      {
                        url: acfPost?.featuredImage?.mediaItemUrl,
                        params: 'w_445,f_auto',
                      },
                    ]}
                    className={s.img}
                  />
                </div>
                {setRelatedModels && (
                  <h2
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: setRelatedModels }}
                  />
                )}
                {title && (
                  <h3
                    className={s.subtitle}
                    dangerouslySetInnerHTML={{
                      __html: title.substr(0, title.lastIndexOf('|')) || title,
                    }}
                  />
                )}
              </Link>
            )
          })}
      </div>
    </div>
  )
}

ArticlesRelated.propTypes = {
  articles: PropTypes.array,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
}
ArticlesRelated.defaultProps = {
  articles: [],
  className: '',
  wrapperClassName: '',
}

export default ArticlesRelated
