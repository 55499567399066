import { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GlobalStateContext } from 'context/GlobalContextProvider'

import Blocks from 'components/Blocks'
import ArticlesRelated from 'components/ArticlesRelated'
import Link from 'components/Link'
import Title from 'components/Title'

import s from './ArticleTemplate.module.css'
import formatDate from '../../helpers/formatDate'

function ArticleTemplate({ data, className, classNameArticles }) {
  const state = useContext(GlobalStateContext)
  const { flashMessageCleared } = state.header

  if (!data?.postBy) return null
  const {
    id,
    title,
    date,
    acfPostSettings,
    acfBlocks,
  } = data.postBy

  const {
    globalBrandTag,
    globalCampaignTag,
    // globalCategoriesTag,
    globalPhotographerTag,
    globalPublicationTag,
    globalStylistTag,
  } = acfPostSettings

  const setSidebarItems = [
    {
      label: 'Brand',
      data: globalBrandTag,
    },
    {
      label: 'Campaign',
      data: globalCampaignTag,
    },
    // NOTE: If you add this make sure to filter out 'Uncategorized'
    //   {
    //   label: 'Cateogory',
    // data: globalCategoriesTag,
    //   },
    {
      label: 'Photographer',
      data: globalPhotographerTag,
    },
    {
      label: 'Publication',
      data: globalPublicationTag,
    },
    {
      label: 'Stylist',
      data: globalStylistTag,
    },
  ]

  const setDate = formatDate(new Date(date))
  const models = acfPostSettings?.relatedModels
  const relatedPosts = models?.[0]?.relatedPosts?.filter((f) => f.id !== id)

  const setSidebarItem = (label, data) => {
    return (
      <div key={label} className={s.tag}>
        <h3 className={s.label} dangerouslySetInnerHTML={{ __html: label }} />
        {data && (
          <div className={s.names}>
            {data.map(({ name }) => (
              <h4
                key={name}
                className={s.name}
                dangerouslySetInnerHTML={{ __html: name }}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className={classNames(s.container, className)}>
        <div className={s.content}>
          {acfBlocks && <Blocks postType="Post">{acfBlocks}</Blocks>}
        </div>
        <div className={s.sidebar}>
          <div className={s.sidebarContent}>
            {title && (
              <Title
                title={title.substr(0, title.indexOf('|')) || title}
                subtitle={
                  title.split('|').length === 2 ? null : title.split('|')[1]
                }
                className={s.title}
              />
            )}
            <div className={s.tags}>
              {models?.length > 0 && (
                <div className={s.tag}>
                  <h3
                    className={s.label}
                    dangerouslySetInnerHTML={{ __html: 'Models' }}
                  />
                  <div className={s.names}>
                    {models.map(({ slug, title }) => (
                      <Link type="Model" href={slug} key={slug}>
                        <h4
                          dangerouslySetInnerHTML={{ __html: title }}
                          className={s.name}
                        />
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              {setSidebarItems?.map((item) => {
                if (!item || item.data.length < 1) return null
                return setSidebarItem(item.label, item.data)
              })}
            </div>
            {setDate && <span className={s.date}>{setDate}</span>}
          </div>
        </div>
      </div>
      <ArticlesRelated
        wrapperClassName={classNameArticles}
        articles={relatedPosts}
      />
    </>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  classNameArticles: PropTypes.string,
}
ArticleTemplate.defaultProps = {
  data: {},
  className: '',
  classNameArticles: '',
}

export default ArticleTemplate
