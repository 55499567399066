import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './Loading.module.css'

function Loading({ initialColours, hideLinks, className, children }) {
  const {
    initialPageColour,
    initialUiColour,
    // initialTextColour,
  } = initialColours
  const setInitialPageColour = initialPageColour ? initialPageColour : '#fff'
  const setInitialUiColour = initialUiColour ? initialUiColour : '#000'
  // const setInitialTextColour = initialTextColour ? initialTextColour : '#000'

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `body { background-color: ${initialPageColour} }
            #header *,
            #header-title * {
              color: ${
                hideLinks ? initialPageColour : initialUiColour
              }!important
            }
            #header *::before,
            #header *::after,
            #header-title-line { 
              background-color: ${
                hideLinks ? initialPageColour : initialUiColour
              }!important
            }`,
        }}
      />
      <div
        className={classNames(s.container, classNames)}
        style={{ backgroundColor: setInitialPageColour }}
      >
        {children}
      </div>
    </>
  )
}

Loading.propTypes = {
  initialColours: PropTypes.object,
  hideLinks: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}
Loading.defaultProps = {
  initialColours: {},
  hideLinks: false,
  className: '',
  children: null,
}

export default Loading
