import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './Title.module.css'

function Title({
  title,
  subtitle,
  swapColour,
  lightUi,
  borderTitle,
  lightSubtitle,
  className,
  shrinkSubtitleLength,
}) {
  if (!title) return null
  return (
    <h2
      className={classNames(s.container, className, {
        [s.swapColour]: swapColour,
        [s.lightUi]: lightUi,
        [s.borderTitle]: borderTitle,
        [s.lightSubtitle]: lightSubtitle,
      })}
    >
      <span dangerouslySetInnerHTML={{ __html: title }} />
      {subtitle && (
        <span
          className={classNames(s.subtitle, {
            [s.subtitleLong]:
              shrinkSubtitleLength && subtitle.length > shrinkSubtitleLength,
          })}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </h2>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  swapColour: PropTypes.bool,
  lightUi: PropTypes.bool,
  borderTitle: PropTypes.bool,
  lightSubtitle: PropTypes.bool,
  className: PropTypes.string,
  shrinkSubtitleLength: PropTypes.number,
}
Title.defaultProps = {
  title: '',
  subtitle: '',
  swapColour: false,
  lightUi: false,
  borderTitle: false,
  lightSubtitle: false,
  className: '',
  shrinkSubtitleLength: null,
}

export default Title
